import React from "react"
import { css } from "@emotion/core"
import MainLayout from "../layouts/Main"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"
import { CardWrapper } from "../components/UI/Card"
import InternalLink from "../components/UI/InternalLink"
import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import ColumnTitle from "components/UI/ColumnTitle"
import List from "components/UI/List"
import linkResolver from "utils/linkResolver"
import sort from "utils/sort"
import t from "theme"
import RichText from "components/UI/RichText"

//uses GraphQL back reference - which automatically links articles back to company
export const query = graphql`
    query($ticker: String!) {
        selectedCompany: contentfulCompany(ticker: { eq: $ticker }) {
            ...CompanyFields
            interview {
                ...InterviewContent
            }
        }
    }
`

const InterviewArchive = ({ pageContext: { commonData, ticker }, data: { selectedCompany }, location }) => {
    const featuredArticles = getFeaturedArticles(commonData, ticker)
    const interviews = selectedCompany.interview

    return (
        <MainLayout
            header={
                <Header
                    commonData={commonData}
                    selectedCompany={selectedCompany}
                    selectedFeature={`INTERVIEWS`}
                    pathname={location.pathname}
                />
            }
            sidebar={<FeaturedArticlesSidebar title={"FEATURED ARTICLES"} featureds={featuredArticles} />}>
            <PageMetadata
                pageTitle={selectedCompany.shortName + " INTERVIEWS"}
                pageDescription={"INTERVIEW ON " + selectedCompany.shortName}
                pagePathname={location.pathname}
            />

            <div>
                <ColumnTitle>{"LATEST INTERVIEWS ON " + selectedCompany.shortName.toUpperCase()}</ColumnTitle>

                <List
                    items={interviews.sort(sort("sortTimestamp", "desc")).map((interview, index) => (
                        <CardWrapper shadow>
                            <InternalLink
                                headline={interview.headline}
                                date={interview.sortTimestamp}
                                pathname={linkResolver({ slug: interview.slug, type: "interview" })}
                                preview={interview.shortDescription.shortDescription}
                            />
                        </CardWrapper>
                    ))}
                    space={t.space[5]}
                    nodivider
                />
            </div>
        </MainLayout>
    )
}

export default InterviewArchive
